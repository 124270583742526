#appDiv {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

#navAndBody {
    flex: 1 0 auto;
}

#footer {
    flex-shrink: 0;
}