.contactDiv {
    max-width: 1600px;
}

#contactPage {
    display: flex;
}

#contactContent {
    display: flex;
}

#contactVr {
    display: flex;
}

#contactHr {
    display: none;
}

#contactForm {
    display: flex;
}

@media screen and (max-width: 767px) {
    #contactVr {
        display: none;
    }

    #contactHr {
        display: block;
    }

    #contactPage {
        display: block;
    }

    #contactContent {
        display: block;
    }

    #contactForm {
        display: block;
    }
}