#aboutMeRoot {
    max-width: 1600px;
}

.aboutMeHeader {
    font-size: 6vw;
}

.desktopIcon {
    display: block;
}

.mobileIcon {
    display: none;
}

@media screen and (max-width: 767px) {
    .aboutMeSection {
        border-style: solid;
        border-color: white;
        border-radius: 5px;
        border-width: 2px;
        padding: 1em;
        margin-bottom: 1em;
    }

    .separator {
        display: none;
    }

    .aboutMeHeaderMobile {
        text-align: center;
    }

    .desktopIcon {
        display: none;
    }

    .mobileIcon {
        display: block;
    }
}

@media screen and (min-width: 900px) {
    .aboutMeHeader {
        font-size: 50px;
    }
}