#animatedIcon {
    display: inline-block;
}

#mobileAnimatedIcon {
    display: none;
}

@media screen and (max-width: 767px) {
    #bulletPoint {
        text-align: center;
    }

    #animatedIcon {
        display: none;
    }

    #mobileAnimatedIcon {
        display: block;
        height: calc(50px + 10vw);
    }

    .headerAlignment {
        text-align: center;
    }
}

.content {
    max-width: 1600px;
}