#contactBtn {
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
    padding: 3px 10px;
}

#desktopLogo {
display: block;
}

#mobileLogo {
    display: none;
}

.vrDiv {
    height: 70px
}

@media screen and (max-width: 767px) {
    #desktopLogo {
        display: none;
    }
        
    #mobileLogo {
        display: block;
    }

    .vrDiv {
        display: none !important;
    }

    .navbarItem {
        border-style: solid;
        border-width: 1px;
        border-radius: 5px;
        margin: 5px;
        width: 100%;
    }

    #contactBtn {
        border-style: solid;
        border-radius: 5px;
        border-width: 1px;
        margin: 5px;
    }

    .customNav {
        padding-right: 0.65em;
    }
}